<template>
  <v-container> </v-container>
</template>

<script>
export default {
  components: {},
  methods: {
    logoutUser() {
      this.$store.dispatch('misc/setLoading', true);
      this.$store
        .dispatch('logoutUser', { force: false })
        .then(response => {
          this.$store.dispatch('misc/setLoading', false);
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
          this.$router.push({ name: 'Login' });
        })
        .catch(error => {
          this.$store.dispatch('misc/setLoading', false);
          console.log(error);
          var snack = {
            text: error.response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
          this.$router.push({ name: 'Login' });
        });
      this.$vuetify.theme.default === 'light'
        ? (this.$vuetify.theme.dark = true)
        : (this.$vuetify.theme.dark = false);
    },
  },
  created() {
    this.logoutUser();
  },
};
</script>

<style></style>
